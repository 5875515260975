'use client';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField as ChakraNumberInputField,
  NumberInputStepper,
  Textarea,
} from '@chakra-ui/react';
import { CSSProperties, HTMLInputTypeAttribute, memo } from 'react';

import { useScopedI18n } from '~/locales/client';
import { FontWeight } from '~/constants';

import PasswordInput from './types/password';
import { Counter } from '../Counter';
import { CounterProps } from '../Counter';
import { DateRangePicker } from '../DateRangePicker/DateRangePicker';
import { SelectInput, SelectProps } from '../Select/Select';
import { Radio, RadioProps } from '../Radio/Radio';
import { Checkbox, CheckboxOptions } from '../Checkbox/Checkbox';
import NumberRange from '../NumberRange/NumberRange';
import { NumberRangeProps } from '../NumberRange/types';
import { DatePicker } from '../DatePicker';

export type FormFieldType =
  | HTMLInputTypeAttribute
  | 'textarea'
  | 'counter'
  | 'select'
  | 'radio'
  | 'checkbox'
  | 'numberrange'
  | 'daterange';

export interface InputProps {
  onChange?: (e: React.ChangeEvent<any>) => void;
  onBlur?: (e: any) => void;
  value?: any;
  name?: string;
  type?: FormFieldType;
  placeholder?: string;
  style?: CSSProperties;
  isDisabled?: boolean;
  counterProps?: CounterProps;
  selectProps?: Omit<SelectProps, 'onChange' | 'value'>;
  radioProps?: Omit<RadioProps, 'onChange' | 'value'>;
  checkboxOptions?: CheckboxOptions;
  numberRangeProps?: NumberRangeProps;
}

export interface FormFieldProps extends InputProps {
  onChange?: (e: any) => void;
  label?: string;
  isInvalid?: boolean;
  errorMessage?: string;
  required?: boolean;
}

const NumberInputField = ({
  name,
  value,
  onChange,
}: Omit<FormFieldProps, 'label' | 'type'>) => {
  return (
    <NumberInput>
      <ChakraNumberInputField
        name={name}
        bg={'gray.50'}
        borderRadius={'xl'}
        value={value}
        fontSize={[16, 14]}
        minWidth={'100%'}
        maxWidth={400}
        onChange={onChange}
      />
      <NumberInputStepper>
        <NumberIncrementStepper />
        <NumberDecrementStepper />
      </NumberInputStepper>
    </NumberInput>
  );
};

export const InputField = ({
  name,
  placeholder,
  onChange,
  onBlur,
  value,
  style,
  type,
  isDisabled,
}: Omit<FormFieldProps, 'label'>) => {
  return (
    <Input
      name={name}
      type={type}
      focusBorderColor="black"
      bg={'gray.50'}
      borderRadius={'xl'}
      placeholder={placeholder}
      value={value}
      fontSize={[16, 14]}
      minWidth={'100%'}
      maxWidth={400}
      onChange={onChange}
      onBlur={onBlur}
      style={style}
      isDisabled={isDisabled}
    />
  );
};

export const TextAreaField = ({
  name,
  placeholder,
  onChange,
  value,
  style,
}: Omit<FormFieldProps, 'label' | 'type'>) => {
  return (
    <Textarea
      name={name}
      focusBorderColor="black"
      bg={'gray.50'}
      borderRadius={'xl'}
      placeholder={placeholder}
      value={value}
      fontSize={[16, 14]}
      minWidth={'100%'}
      maxWidth={400}
      onChange={onChange}
      style={style}
    />
  );
};

const FormComponent = memo(
  ({
    name,
    value,
    placeholder,
    onChange,
    onBlur,
    type,
    style,
    isDisabled,
    counterProps,
    selectProps,
    radioProps,
    checkboxOptions,
    numberRangeProps,
  }: InputProps) => {
    const t = useScopedI18n('component.form-field');

    const handleEventChange = (event: React.ChangeEvent<any>) => {
      const value = event?.target?.value;
      if (onChange) {
        onChange(value ?? '');
      }
    };

    const handleValueChange = (value: any) => {
      if (onChange) {
        onChange(value ?? '');
      }
    };

    if (type === 'password') {
      return (
        <PasswordInput
          name={name}
          value={value}
          style={style}
          onChange={handleEventChange}
        />
      );
    } else if (type === 'textarea') {
      return (
        <TextAreaField
          name={name}
          placeholder={placeholder}
          value={value}
          style={style}
          onChange={handleEventChange}
        />
      );
    } else if (type === 'number') {
      return (
        <NumberInputField
          name={name}
          value={value}
          onChange={handleEventChange}
        />
      );
    } else if (type === 'counter') {
      return (
        <Counter
          {...counterProps!}
          value={value}
          onChange={handleValueChange}
        />
      );
    } else if (type === 'select') {
      return (
        <SelectInput
          {...selectProps!}
          name={name}
          value={value}
          placeholder={placeholder || t('select.placeholder')}
          onChange={handleValueChange}
          onBlur={onBlur}
        />
      );
    } else if (type === 'radio') {
      return (
        <Radio
          {...radioProps!}
          name={name}
          value={value}
          onChange={handleValueChange}
          onBlur={onBlur}
        />
      );
    } else if (type === 'checkbox') {
      return (
        <Checkbox
          checkboxOptions={checkboxOptions!}
          value={value}
          onChange={handleValueChange}
          onBlur={onBlur}
        />
      );
    } else if (type === 'numberrange') {
      return numberRangeProps && <NumberRange {...numberRangeProps} />;
    } else if (type === 'daterange') {
      return <DateRangePicker value={value} onChange={handleValueChange} />;
    } else if (type === 'date') {
      return <DatePicker value={value} onChange={handleValueChange} />;
    }

    return (
      <InputField
        name={name}
        style={style}
        placeholder={placeholder}
        value={value}
        onChange={handleEventChange}
        onBlur={onBlur}
        isDisabled={isDisabled}
      />
    );
  }
);

FormComponent.displayName = 'FormComponent';

const FormField: React.FC<FormFieldProps> = ({
  name,
  label,
  errorMessage,
  style,
  required = false,
  isInvalid,
  onChange,
  ...props
}) => {
  return (
    <FormControl mb={30} isInvalid={isInvalid} style={style}>
      <FormLabel fontWeight={FontWeight.Bold} fontSize={12} mb={'4px'}>
        {label}
      </FormLabel>
      <FormComponent {...props} name={name} onChange={onChange} />
      {errorMessage && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
    </FormControl>
  );
};

interface FormFieldLayout {
  label: string | React.ReactElement;
  errorMessage?: string;
  style?: CSSProperties;
  isInvalid?: boolean;
  children: React.ReactNode;
}

export const FormFieldLayout: React.FC<FormFieldLayout> = ({
  label,
  errorMessage,
  style,
  isInvalid,
  children,
}) => {
  return (
    <FormControl mb={30} isInvalid={isInvalid} style={style}>
      <FormLabel fontWeight={600} fontSize={12} mb={1}>
        {label}
      </FormLabel>
      {children}
      {errorMessage && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
    </FormControl>
  );
};

export default FormField;
