import {
  Flex,
  NumberInput,
  NumberInputField,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
} from '@chakra-ui/react';
import React from 'react';

import { Button } from './Button';

export interface CounterProps {
  amount: number;
  name?: string;
  value: number;
  withSlider?: boolean;
  min?: number;
  max: number;
  onChange?: (v: number) => void;
  ticker?: string;
  maxWidth?: number | 'full';
}

function NumberSection({
  value,
  onChange,
  placeholder,
  max = 50,
  ticker,
  name,
}: {
  onChange?: (v: number) => void;
  value: number;
  placeholder?: string;
  max?: number;
  ticker?: string;
  name?: string;
}) {
  const format = (val: number) => (ticker ? ticker + val : val);
  const parse = (val: string) => Number(ticker ? val.replace(ticker, '') : val);

  const onChangeHandler = (value: string) => {
    onChange && onChange(parse(value));
  };
  const numberInputValue = format(value);
  return (
    <>
      <NumberInput
        mx="1"
        bg="gray.50"
        w={20}
        flexGrow={1}
        focusBorderColor="black"
        onChange={onChangeHandler}
        value={numberInputValue}
        max={max}
        name={name}
      >
        <NumberInputField width="100%" px="15px" placeholder={placeholder} />
      </NumberInput>
    </>
  );
}

const Counter = ({
  amount,
  value,
  withSlider = false,
  max,
  min = 0,
  onChange,
  name,
  ticker,
  maxWidth,
}: CounterProps) => {
  const handleIncrease = () => {
    let res = Number(value + amount);
    res = res >= max ? max : res;

    onChange && onChange(res);
  };

  const handleDecrease = () => {
    let res = value - amount;
    res = res > min ? res : min;

    onChange && onChange(res);
  };

  return (
    <>
      <Flex
        alignItems="center"
        align={'stretch'}
        flex={1}
        w={'full'}
        maxW={maxWidth}
      >
        <Button
          onClick={handleDecrease}
          mode="transparent"
          withBorder
          style={{
            maxWidth: 40,
            minHeight: '100%',
            borderColor: '#A0AEC0',
          }}
        >
          -
        </Button>
        <NumberSection
          onChange={onChange}
          value={value}
          max={max}
          ticker={ticker}
          name={name}
        />
        <Button
          onClick={handleIncrease}
          mode="transparent"
          withBorder
          style={{
            maxWidth: 40,
            minHeight: '100%',
            borderColor: '#A0AEC0',
          }}
        >
          +
        </Button>
      </Flex>
      {withSlider && (
        <Slider
          max={max}
          flex="1"
          focusThumbOnChange={false}
          value={value}
          onChange={onChange}
        >
          <SliderTrack bg="red.100">
            <SliderFilledTrack bg="tomato" />
          </SliderTrack>
          <SliderThumb fontSize="sm" boxSize="32px">
            {value}
          </SliderThumb>
        </Slider>
      )}
    </>
  );
};

export { Counter };
