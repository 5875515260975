'use client';
import { InputGroup, InputRightElement } from '@chakra-ui/react';
import { useState } from 'react';

import { InputProps, InputField } from '../FormField';
import { Icon, IconsName } from '../../Icon';

function PasswordInput({
  onChange,
  placeholder,
  value,
}: Omit<InputProps, 'type'>) {
  const [showPassword, setShowPassword] = useState(false);
  const handleTogglePassword = () => setShowPassword(!showPassword);

  return (
    <InputGroup pb={1}>
      <InputField
        type={showPassword ? 'text' : 'password'}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
      />
      <InputRightElement pl="-4.5rem" width="4.5rem">
        <Icon
          _hover={{
            cursor: 'pointer',
          }}
          onClick={handleTogglePassword}
          name={showPassword ? IconsName.EyeClosed : IconsName.EyeOpen}
          aria-label={showPassword ? 'hide password' : 'reveal password'}
        />
      </InputRightElement>
    </InputGroup>
  );
}

export default PasswordInput;
