import isEmpty from 'lodash.isempty';
import {
  CategoryType,
  SupportedCategoryType,
  ValueMappingType,
} from '~/app/[locale]/home/store/types';
import { findKeyByValue, localizeAmountWithCurrency } from './generalUtils';
import { fetchCategories } from '~/app/[locale]/home/store/requests';
import {
  ParamValueType,
  Product,
} from '~/app/[locale]/home/store/[category]/[id]/types';
import { ProductCategory } from './claimUtils';
import { Filters } from '~/atoms';
import { parameterIDs } from '~/constants/store-parameter-ids';

export enum ProductCategoryBE {
  Inverters = 'PV Inverters',
  Modules = 'PV Modules',
  Batteries = 'PV Batteries',
  Other = 'Other',
}

export enum ProductCategoryFE {
  Modules = 'modules',
  Batteries = 'batteries',
  Inverters = 'inverters',
  Cables = 'cables',
}

export const valueMapping = {
  [ProductCategoryBE.Modules]: ProductCategoryFE.Modules,
  [ProductCategoryBE.Batteries]: ProductCategoryFE.Batteries,
  [ProductCategoryBE.Inverters]: ProductCategoryFE.Inverters,
  [ProductCategoryBE.Other]: ProductCategoryFE.Cables,
};

export const getOnlySupportedCategories = (allCategories: CategoryType[]) => {
  const supportedCategoryNames = Object.keys(valueMapping);

  const supportedCategories = allCategories
    .map((category) => {
      if (supportedCategoryNames.includes(category.name))
        return {
          value: valueMapping[category.name as keyof typeof valueMapping],
          id: category.id,
        };
    })
    .filter((item) => item !== undefined);
  return supportedCategories;
};

export const getSanitizedCategoryName = (
  name?: string | null
): string | null => {
  if (!name) return null;
  const typedValueMapping: ValueMappingType = valueMapping;
  return typedValueMapping[name] ?? null;
};

/**
 * Sets parameters based on the searchParams object.
 *
 * @param {Object} searchParams - An object containing key-value pairs for parameters.
 * @return {string} The concatenated string of parameters for the URL.
 */
export const setParameters = (searchParams: {
  [key: string]: string | undefined;
}) => {
  if (isEmpty(searchParams)) return '';
  let str = '';

  for (const [key, value] of Object.entries(searchParams)) {
    if (key === 'query') {
      str = str;
    } else if (key === 'sort') {
      str = str + `&${key}=${value}`;
    } else if (key === 'category') {
      const allValues = value?.split(',');
      let categoryNames = '';
      allValues?.forEach((value) => {
        const key = findKeyByValue(valueMapping, value);
        if (key) {
          categoryNames =
            categoryNames.length > 0 ? categoryNames + `;${key}` : key;
        }
      });

      str =
        str +
        `&${encodeURIComponent('category.name=')}${encodeURIComponent(
          categoryNames
        )}`;
    } else {
      str = `&parameter=${key}:${value}`;
    }
  }

  return str;
};

export const findSelectedCategory = async (categoryName: string) => {
  const categories = await fetchCategories();
  const selectedCategory = categories?.find(
    (category: SupportedCategoryType) => category.value === categoryName
  );

  return selectedCategory || null;
};

export const returnPriceString = (price: number, quantity?: number) => {
  const calculatedPrice = quantity ? price * quantity : price;
  return localizeAmountWithCurrency(calculatedPrice);
};

export const isOnlySearch = (allParams: {
  [key: string]: string | undefined;
}) => {
  const array = Object.keys(allParams);
  return array.length === 1 && array[0] === 'query';
};

export const getByParameter = ({
  array,
  param,
}: {
  array?: ParamValueType[];
  param: string;
}): string => {
  if (!array || !Array.isArray(array) || array.length === 0 || !param) {
    return '';
  }
  const env = process.env.NEXT_PUBLIC_ENV?.toLocaleLowerCase() ?? 'dev';
  // @ts-ignore
  const paramIds = parameterIDs?.[param as any]?.[env];

  const paramObject = array.find((item) =>
    paramIds?.includes(item.parameterId)
  );

  if (!paramObject || !paramObject?.value) {
    return '';
  }
  return paramObject ? paramObject.value : '';
};

export const mapProductsForResultList = (productsArray: Product[]) => {
  return productsArray.map((product) => {
    return {
      id: product.id,
      name: product.name,
      brand: product.parameterValues
        ? getByParameter({ array: product.parameterValues, param: 'Brand' })
        : '',
      category: {
        id: product.category.id,
        name: product.category.name as ProductCategory,
      },
      productImage: {
        value: product.productImage?.value,
      },
      price: calculateUnitPrices(
        product.measurements,
        (product?.price as ParamValueType)?.value as string
      ).price,
    };
  });
};

export const calculateUnitPrices = (
  measurements?: ParamValueType[],
  unitPrice?: string
) => {
  let pricePerUnit = 0;
  if (unitPrice) {
    pricePerUnit = parseFloat(unitPrice);
  }
  const unitsPerContainer = getByParameter({
    array: measurements,
    param: 'units_per_container',
  });

  const unitsPerPallet = getByParameter({
    array: measurements,
    param: 'units_per_pallet',
  });

  const unitsPerContainerValue = unitsPerContainer
    ? parseInt(unitsPerContainer, 10)
    : 0;

  const unitsPerPalletValue = unitsPerPallet ? parseInt(unitsPerPallet, 10) : 0;

  return {
    price: {
      unit: pricePerUnit,
      piece: pricePerUnit,
      pallet: pricePerUnit * unitsPerPalletValue,
      container: pricePerUnit * unitsPerContainerValue,
    },
  };
};

export const isAnyFilterSet = (filters: Filters) => {
  return Object.entries(filters).some(([key, value]) => {
    if (key === 'priceRange') {
      return (
        Array.isArray(value) &&
        value.length === 2 &&
        value.every((num) => typeof num === 'number')
      );
    }
    return value !== undefined;
  });
};

export const moveLongestValueToEnd = (array?: ParamValueType[]) => {
  if (isEmpty(array) || !array) return null;

  const longestValueObject = array.reduce((acc, curr) => {
    const accLength = acc?.value?.length ?? 0;
    const currLength = curr?.value?.length ?? 0;

    if (currLength > accLength) {
      return curr;
    }
    return acc;
  }, array[0]);

  // 45 is ~ two lines
  if ((longestValueObject?.value?.length ?? 0) <= 45) {
    return array;
  }

  const indexToRemove = array.indexOf(longestValueObject);
  if (indexToRemove !== -1) {
    array.splice(indexToRemove, 1);
  }

  array.push(longestValueObject);
  return array;
};

export const storeCategories = [
  {
    icon: ProductCategoryFE.Modules,
    name: 'navigation.modules',
    link: ProductCategoryFE.Modules,
  },
  {
    icon: ProductCategoryFE.Inverters,
    name: 'navigation.inverters',
    link: ProductCategoryFE.Inverters,
  },
  {
    icon: ProductCategoryFE.Batteries,
    name: 'navigation.batteries',
    link: ProductCategoryFE.Batteries,
  },
  {
    icon: ProductCategoryFE.Cables,
    name: 'navigation.cables',
    link: ProductCategoryFE.Cables,
  },
];
