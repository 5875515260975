'use client';
import { SingleDatepicker } from 'chakra-dayzed-datepicker';
import { useState } from 'react';
import { DD_MM_YYYY } from '~/constants';
import Flex from '../customComponents/flex/Flex';

const DatePicker = ({
  onChange,
  value,
}: {
  onChange: (value: string) => void;
  value?: Date;
}) => {
  const [date, setDate] = useState(value ?? new Date());

  return (
    <Flex className="w-full">
      <SingleDatepicker
        name="date"
        date={date}
        onDateChange={(date) => {
          onChange(date.toString());
          setDate(date);
        }}
        triggerVariant="input"
        propsConfigs={{
          dateNavBtnProps: {
            variant: 'outline',
            width: '100%',
          },
          popoverCompProps: {
            popoverBodyProps: {
              border: 'none',
              p: 1,
              placeContent: 'center',
            },
          },
          calendarPanelProps: {
            contentProps: {
              border: 'none',
              borderColor: 'gray.200',
            },
          },
          dayOfMonthBtnProps: {
            defaultBtnProps: {
              _hover: {
                background: 'red.400',
                color: 'white',
              },
              _disabled: {
                color: 'gray.300',
                _hover: {
                  background: 'transparent',
                  color: 'gray.300',
                },
              },
              width: '100%',
            },
            selectedBtnProps: {
              background: 'brand-red.500',
              color: 'white',
            },
            isInRangeBtnProps: {
              background: 'brand-red.500',
              color: 'white',
            },
            todayBtnProps: {
              fontWeight: 'bold',
            },
          },
        }}
        configs={{
          dateFormat: DD_MM_YYYY,
        }}
      />
    </Flex>
  );
};

export { DatePicker };
