'use client';
import { RangeDatepicker } from 'chakra-dayzed-datepicker';
import { useEffect, useState } from 'react';

import { DD_MM_YYYY } from '~/constants';
import {
  parseDateRangeStringToDates,
  parseDatesToDateRangeString,
} from '~/utils/dateUtils';

export const DateRangePicker = ({
  onChange,
  value: incomingValue,
}: {
  value: string;
  onChange: (value: string) => void;
}) => {
  const [selectedDates, setSelectedDates] = useState<Date[]>([
    new Date(),
    new Date(),
  ]);
  useEffect(() => {
    setSelectedDates(parseDateRangeStringToDates(incomingValue));
  }, [incomingValue]);

  const today = new Date();

  return (
    <>
      <RangeDatepicker
        selectedDates={selectedDates}
        onDateChange={(dates) => {
          setSelectedDates(dates);
          onChange(parseDatesToDateRangeString(dates));
        }}
        minDate={today}
        triggerVariant="input"
        propsConfigs={{
          dateNavBtnProps: {
            variant: 'outline',
          },
          inputProps: {
            width: '100%',
          },
          popoverCompProps: {
            popoverBodyProps: {
              border: 'none',
              p: 1,
              placeContent: 'center',
            },
          },
          calendarPanelProps: {
            contentProps: {
              border: 'none',
              borderColor: 'gray.200',
            },
          },
          dayOfMonthBtnProps: {
            defaultBtnProps: {
              _hover: {
                background: 'red.400',
                color: 'white',
              },
              _disabled: {
                color: 'gray.300',
                _hover: {
                  background: 'transparent',
                  color: 'gray.300',
                },
              },
            },
            selectedBtnProps: {
              background: 'brand-red.500',
              color: 'white',
            },
            isInRangeBtnProps: {
              background: 'brand-red.500',
              color: 'white',
            },
            todayBtnProps: {
              fontWeight: 'bold',
            },
          },
        }}
        configs={{
          dateFormat: DD_MM_YYYY,
          firstDayOfWeek: 1,
        }}
      />
    </>
  );
};
