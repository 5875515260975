'use client';

import Image from 'next/image';
import { Icon, IconsName } from '~/components/core/Icon';
import { getSanitizedCategoryName } from '~/utils/storeUtils';
import { spacing, colors } from '~/utils/tailwindUtils';
import { ProductCategory } from '~/utils/claimUtils';
import { twMerge } from 'tailwind-merge';

export const ProductImage = ({
  imageUrl,
  productName,
  productCategory,
  size = 'sm',
  className = '',
  isTransparent = false,
}: {
  imageUrl?: string;
  productName?: string;
  productCategory?: ProductCategory | null;
  size?: 'sm' | 'lg'; // TODO: Replace other instances with this one and Prop large
  className?: string;
  isTransparent?: boolean;
}) => {
  const imageLoader = ({ src, width, quality }: any) => {
    return `${src}?w=${width}&q=${quality || 75}`;
  };

  if (imageUrl) {
    return (
      <Image
        src={imageUrl}
        alt={productName ?? ''}
        className={twMerge(isTransparent ? 'mix-blend-darken' : '', className)}
        width={size === 'sm' ? spacing[6] : spacing[60]}
        height={size === 'sm' ? spacing[8] : spacing[60]}
        loader={imageLoader}
      />
    );
  }
  return (
    <Icon
      name={getSanitizedCategoryName(productCategory) ?? IconsName.Shop}
      size={size === 'sm' ? spacing[5] : spacing[12]}
      color={colors.gray[400]}
      className={className}
    />
  );
};
