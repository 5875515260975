'use client';
import { useState } from 'react';
import {
  Checkbox as ChakraCheckbox,
  CheckboxGroup as ChakraCheckboxGroup,
  Stack,
} from '@chakra-ui/react';

import { useScopedI18n } from '~/locales/client';

import { Button } from '../Button';

type CheckboxOption = {
  key: string;
  value: string;
  label: string;
  // sublabel?: string;
  // icon?: string;
};

export type CheckboxOptions = CheckboxOption[];

export interface CheckboxProps {
  checkboxOptions: CheckboxOptions;
  onChange: (e: any) => void;
  onBlur?: (e: any) => void;
  value: (string | number)[];
}

const Checkbox = ({ checkboxOptions = [], onChange, value }: CheckboxProps) => {
  const t = useScopedI18n('component.checkbox');

  const onSetValue = (value: (string | number)[]) => {
    onChange(value);
  };
  const [isExpanded, setIsExpanded] = useState(false);
  const displayLimit = 6;

  const handleToggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  return (
    <ChakraCheckboxGroup onChange={onSetValue} value={value}>
      <Stack>
        {checkboxOptions
          .slice(0, isExpanded ? checkboxOptions.length : displayLimit)
          .map((option) => (
            <ChakraCheckbox
              key={option.key}
              value={option.value}
              my={2}
              colorScheme={'brand-red'}
            >
              {option.label}
            </ChakraCheckbox>
          ))}
      </Stack>
      {checkboxOptions.length > displayLimit && (
        <Button mode="link" onClick={handleToggleExpand} mt={4}>
          {isExpanded ? t('show-less') : t('show-more')}
        </Button>
      )}
    </ChakraCheckboxGroup>
  );
};

export { Checkbox };
