export const parameterIDs = {
  units_per_pallet: {
    prod: [
      'a59dc691-4fbe-4519-aa50-674ecee74ea4',
      '4df6041d-5ff6-4d3d-8dfa-fd9ce471af68',
      'b9e229a7-c1e8-4590-b1c9-c2c54cd21fc6',
      '2e0d831b-a3aa-433d-ba52-85f675660b73',
    ],

    dev: [
      '6654025b-fd3c-483e-b3ea-ccdc7c67039b',
      'c21e86f2-e6b6-48cb-90c8-6ea6f9197f77',
      'f53d73db-c09d-4b3c-909a-510716859ecd',
      '1d28e395-a97d-4b9f-bc0a-3210d6b568e2',
    ],
  },
  units_per_container: {
    prod: [
      '20af0818-c15f-4f09-a6f1-d71068ba73f3',
      'e5db0a64-2797-4963-896b-d0ad2b5906a1',
      'a0b557ee-a15d-4478-951e-fbe750968f7a',
      'fcdecc8d-95bb-4c55-85d2-590266f8fff8',
    ],
    dev: [
      '87cf561b-566d-4b49-89ab-e877fe2fae39',
      '2f2d39a6-ba5f-42ea-948e-dd5f0b350a83',
      '471fa7eb-ffad-4621-b2c5-20fe815f47e1',
      '6c8fc4af-2ce1-4c2a-8189-c5a6932023a1',
    ],
  },
  minimum_order_quantity: {
    prod: ['c72647b0-901b-4bb1-a5a7-67f25f39433b'],
    dev: ['e43863ae-3fae-4956-97b3-13a7250c4a28'],
  },
  minimum_order_unit: {
    prod: ['71f3a23c-5e1e-44bd-a3f8-cbecef73e595'],
    dev: ['5f054152-ca49-48e2-b8d2-ca26eb29000f'],
  },
};
