import { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

type FlexProps = {
  className?: string;
  children?: string | React.ReactNode;
} & React.HTMLAttributes<HTMLDivElement>;

const Flex = forwardRef<HTMLDivElement, FlexProps>(
  ({ className = '', children, ...props }, ref) => {
    return (
      <div
        className={twMerge('flex flex-row justify-start', className)}
        ref={ref}
        {...props}
      >
        {children}
      </div>
    );
  }
);

Flex.displayName = 'Flex';
export default Flex;
