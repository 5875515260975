'use client';
import { Flex, Text } from '@chakra-ui/react';
import {
  ChakraStylesConfig,
  Select,
  chakraComponents,
  useChakraSelectProps,
} from 'chakra-react-select';
import { useState } from 'react';

import { useI18n } from '~/locales/client';

import { twMerge } from 'tailwind-merge';
import { ProductImage } from '~/app/[locale]/home/_components/Products/ProductImage';
import { FontWeight } from '~/constants';

export interface SelectProps {
  placeholder: string;
  options: SelectOption[];
  onChange: (e: any) => void;
  onBlur?: (e: any) => void;
  value: any;
  name?: string;
  customLabelStyle?: 'Fulfillment' | 'Product' | 'Invoice';
}

export type SelectOption = {
  key: string;
  value: string | number;
  label?: string | number;
  labelKey?: string;
  subLabel?: string;
};

const getCustomComponents = (customLabelStyle?: string, label?: string) => {
  if (customLabelStyle === 'Product') {
    return {
      // @ts-ignore
      Option: ({ children, ...props }) => (
        // @ts-ignore
        <chakraComponents.Option {...props}>
          <Flex flexDirection="row" alignItems={'center'} py="4">
            <Flex pr={4}>
              <ProductImage
                className={twMerge(
                  'mr-2',
                  !props.isSelected ? 'mix-blend-darken' : ''
                )}
                imageUrl={props.data.subLabel}
              />
            </Flex>
            <Text
              color={props.isSelected ? 'white' : 'gray.700'}
              fontWeight={FontWeight.Normal}
              fontSize="md"
            >
              {children}
            </Text>
          </Flex>
        </chakraComponents.Option>
      ),
    };
  }

  if (customLabelStyle === 'Fulfillment') {
    return {
      // @ts-ignore
      Option: ({ children, ...props }) => (
        // @ts-ignore
        <chakraComponents.Option {...props}>
          <Flex flexDirection="column">
            <Text
              color={props.isSelected ? 'white' : 'gray.900'}
              fontWeight={FontWeight.Normal}
              fontSize="md"
            >
              {children}
            </Text>
            <Text
              color={props.isSelected ? 'gray.300' : 'gray.700'}
              fontWeight={FontWeight.Normal}
              fontSize="sm"
            >
              {props.data.subLabel}
            </Text>
          </Flex>
        </chakraComponents.Option>
      ),
      // @ts-ignore
      NoOptionsMessage: (props) => (
        <chakraComponents.NoOptionsMessage {...props}>
          <Text color="gray.700" fontWeight={FontWeight.Normal}>
            {label}
          </Text>
        </chakraComponents.NoOptionsMessage>
      ),
    };
  }
  if (customLabelStyle === 'Invoice') {
    return {
      // @ts-ignore
      NoOptionsMessage: (props) => (
        <chakraComponents.NoOptionsMessage {...props}>
          <Text color="gray.700" fontWeight={FontWeight.Normal}>
            {label}
          </Text>
        </chakraComponents.NoOptionsMessage>
      ),
    };
  }
  return;
};

const chakraStyles: ChakraStylesConfig = {
  control: (provided, state) => ({
    ...provided,
    borderRadius: '40px',
    backgroundColor: 'gray.50',
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? 'white' : 'black',
    backgroundColor: state.isSelected ? 'red' : 'white',
    ':hover': {
      backgroundColor: state.isSelected ? 'red' : 'gray.200',
    },
    _selected: {
      backgroundColor: state.isSelected ? 'brand-red.500' : 'gray.200',
    },
  }),
  // noOptionsMessage: (provided, state) => ({}),
};

const SelectInput = ({
  options,
  placeholder,
  onChange,
  onBlur,
  value,
  name,
  customLabelStyle,
}: SelectProps) => {
  const [selectedOption, setSelectedOption] = useState<SelectOption | null>(
    null
  );
  const t = useI18n();

  const label = t('select.isEmpty', {
    selectObject:
      customLabelStyle === 'Fulfillment'
        ? t('select.fulfillments')
        : t('select.invoices'),
  });

  const onSelectChange = (newValue: SelectOption | null) => {
    setSelectedOption(newValue);
    onChange(newValue?.value);
  };

  const selectProps = useChakraSelectProps({
    value: selectedOption,
    // @ts-ignore
    onChange: onSelectChange,
    components: customLabelStyle
      ? getCustomComponents(customLabelStyle, label)
      : undefined,
    chakraStyles,
  });

  const updatedOptions = options.map((option) => {
    return {
      ...option,
      label:
        !option.label && option.labelKey
          ? // @ts-ignore
            t(option.labelKey as any)
          : option.label,
    };
  });

  return (
    <Select
      placeholder={placeholder}
      focusBorderColor="black"
      onBlur={onBlur}
      name={name}
      value={value}
      options={updatedOptions}
      useBasicStyles
      components={
        customLabelStyle
          ? getCustomComponents(customLabelStyle, label)
          : undefined
      }
      selectedOptionColorScheme="brand-red"
      {...selectProps}
    />
  );
};

export { SelectInput };
