'use client';

import { createI18nClient } from 'next-international/client';
import { translationFiles } from './resources';

const {
  useI18n: originalUseI18n,
  useScopedI18n: originalUseScopedI18n,
  I18nProviderClient,
  useCurrentLocale,
  useChangeLocale,
  I18nClientContext,
} = createI18nClient(translationFiles);

// Helper functions to provide default values for i18n
const useI18n = () => {
  const i18n = originalUseI18n();

  const translateWithDefault = (
    key: string,
    params: Record<string, any> = {}
  ) => {
    // @ts-ignore - there was no workaround for this, so I had to ignore it
    return i18n(key as never, { count: 0, ...params });
  };

  return translateWithDefault;
};

// Helper functions to provide default values for scoped i18n
const useScopedI18n = (namespace: string) => {
  const i18n = originalUseScopedI18n(namespace as never);

  const translateWithDefault = (
    key: string,
    params: Record<string, any> = {}
  ) => {
    return i18n(key as never, { count: 0 as never, ...params });
  };

  return translateWithDefault;
};

export {
  useI18n,
  useScopedI18n,
  I18nProviderClient,
  useCurrentLocale,
  useChangeLocale,
  I18nClientContext,
};
